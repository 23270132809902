<template>
  <div class="page">
    <div class="container">
      <div class="row">
        <div class="col">

        </div>
        <div class="col">
          <div class="card">
            <div class="card-header">デバイスやネットワーク確認</div>
            <div class="card-body">
              <b-form @submit="onSubmit">
                <div class="biz-form-group">
                  <div class="biz-form-body">
                    <b-form-group class="biz-input-group" label="認証番号">
                      <b-form-input type="text" v-model="form.recognitionCode" required></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <div class="text-center mt-4">
                  <j-button buttonType="submit" variant="primary">確認</j-button>
                </div>
              </b-form>
            </div>
          </div>
        </div>
        <div class="col">

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Login from '@/model/Login.js';
  export default {
    components: {},
    data: () => ({
      form: {}
    }),
    methods: {
      onSubmit(event) {
        event.preventDefault();
        Login.validateDevice(this.form).then(result => {
          this.$store.commit("twoFactorValidated", {
            accessToken: result.data.message,
            cb: () => {
              this.$router.push({
                name: "MainMenu"
              });
            }
          });
        }).catch(reason => {
          this.$errorMessage("認証番号を確認出来ませんでした。", {reason});
        })

      },
    }
  }
</script>

<style lang="scss" scoped>

</style>